import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class StatService {
    constructor(private http: HttpClient) { }

    getDoughnut(content: string = '', country: string = '', userType: string = '') {
      let params = '?'

      if(content) {
        params+= `filter[content][eq]=${content}&`
      }

      if(country) {
        params+= `filter[country][eq]=${country}&`
      }

      if(userType !== '') {
        params+= `filter[role][eq]=${userType}&`
      }

      return this.http.get(`${environment.urlApi}/stats/aggregation${params}`);
    }

    getBars(content: string = '', country: string = '', userType: string = '') {
      let params = '?'

      if(content) {
        params+= `filter[content][eq]=${content}&`
      }

      if(country) {
        params+= `filter[country][eq]=${country}&`
      }

      if(userType !== '') {
        params+= `filter[role][eq]=${userType}&`
      }

      return this.http.get(`${environment.urlApi}/stats/daily-monthly${params}`);
    }

}
