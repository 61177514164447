
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ViewerService {

  constructor(
      private http: HttpClient
  ) { }

  getAutodeskToken(data) {
    return this.http.post(`${environment.urlApi}/viewer-configuration`, data)
      .pipe(
        map( result => result)
      );
  }

  uploadFile(data, nameFile: string, token: string) {
    return this.http.put(`https://developer.api.autodesk.com/oss/v2/buckets/tectonica/objects/${nameFile}`, data, {
        headers: new HttpHeaders().set('Authorization', token)
      })
      .pipe(
        map( result => result)
      );
  }
}

